/* Contenedor principal */
.pagos-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f4f4f4;
    border-radius: 10px;
}

/* Sección de pagos */
.pagos-section {
    background-color: var(--background-color);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Título de pagos */
.pagos-title {
    font-size: 1.5rem;
    color: var(--text-color);
    font-weight: bold;
    text-align: center;
    margin: 0;
}

/* Filtros de búsqueda */
.search-filters {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
}

.search-filters select,
.search-filters input {
    border: 1px solid var(--input-border);
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #fff;
}

.search-filters select {
    width: 180px;
}

.search-filters input {
    width: 150px;
}

/* Tabla de pagos */
.pagos-table {
    margin-top: 1rem;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.pagos-table th,
.pagos-table td {
    padding: 12px 15px;
    border: 1px solid var(--border-color);
    text-align: center;
    color: var(--text-color);
}

.pagos-table th {
    background-color: var(--table-header);
    font-weight: bold;
    color: var(--table-header-text);
}

.pagos-table tr:nth-child(odd) td {
    background-color: var(--table-cell-odd);
}

.pagos-table tr:nth-child(even) td {
    background-color: var(--table-cell-pair);
}

.pagos-table tr:hover td {
    background-color: var(--table-cell-hover);
    color: white;
    cursor: pointer;
}

/* Paginación */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.pagination button {
    background-color: var(--button-green);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: var(--button-hover-green);
}

.pagination button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.pagination select {
    border: 1px solid var(--input-border);
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: white;
    margin: 0 0.5rem;
}
