input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    box-shadow: 0 0 0 1000px #ffffff inset !important;
    background-color: #ffffff !important;
  }
  
  .consumos-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .consumos-section {
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #f5fcf9, #f1f9f5); /* Ajusta los colores del gradiente aquí */
    padding: 2rem;
    padding-top: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 102vh;
  }

  
  .consumos-encabezado {
    position: relative; /* Para que el logo se posicione en relación a este contenedor */
    text-align: center; /* Centra el texto del encabezado */
    /* padding: 1rem; Espacio alrededor del encabezado */
    }

    .consumos-title {
        font-size: 1.5rem; /* Tamaño de fuente del título */
        margin: 0; /* Remueve márgenes extra */
    }

    .consumos-logo {
        position: absolute; /* Posiciona el logo de forma absoluta */
        top: 0px; /* Ajusta la posición desde la parte superior */
        right: 10px; /* Ajusta la posición desde la derecha */
        width: 14rem; /* Ancho del logo */
    }

    .consumos-grafico{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 17rem;
        margin-bottom: 1rem;
    }

  .consumos-title {
    font-size: 1.7rem;
    color: var(--dark-green);
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0px;
    
  }
  
  .consumos-cliente{
    width: 22rem;
  }
  
  .consumos-servicio{
    width: 10rem;
  }
  
  .consumos-busqueda {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
  }
  
  .consumos-busqueda label {
    color: var(--color-label);
    font-size: 1.1rem;
  }
  
  .consumos-busqueda select {
    border: 1px solid var(--input-border);
    font-size: 1.1rem;
    padding: 0 5px;
    height: 1.8rem;
    border-radius: 5px;
    background-color: white;
  }
  
  .consumos-busqueda input {
    border: 1px solid var(--input-border);
    font-size: 1.1rem;
    padding: 0 5px;
    height: 1.8rem;
    border-radius: 5px;
    background-color: white;
    width: 20rem;
  }
  
  .consumos-busqueda button{
    height: 2.5rem;
    width: 8rem;
    font-size: 1rem;
    background-color: var(--button-green);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .consumos-busqueda button:hover {
    background-color: var(--button-hover-green);
    scale: 1.05;
  }
  
  .consumos-resultados {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0.1rem;
  }
  
  .consumos-titulo-tabla{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 0rem;
  }
  
  .consumos-lectura-buttons{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
  }
  
  .consumos-lectura-buttons button{
    height: 3rem;
    width: 8rem;
    font-size: 1rem;
    background-color: var(--button-green);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .consumos-lectura-buttons button:hover{
    background-color: var(--button-hover-green);
    scale: 1.05;
  }
  
  .consumos-lectura-buttons label{
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-green)
  }
  
  .consumos-resultados .resultado-item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 1.5rem;
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  .consumos-resultados .resultado-item .row {
    display: flex;
    flex-direction: column;
  }
  
  .consumos-resultados .resultado-item .row label {
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
  }
  
  .consumos-resultados .resultado-item .row h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .consumos-resultados p {
    font-size: 1.2rem;
    color: #777;
    text-align: center;
  }
  

