/* Estilos para evitar el autofill amarillo en Chrome */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  box-shadow: 0 0 0 1000px #ffffff inset !important;
  background-color: #ffffff !important;
}

/* Contenedor principal */
.historial-servicios-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Sección del historial de servicios */
.historial-servicios-section {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 2rem; /* Aumentamos el padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Título */
.historial-servicios-title {
  font-size: 2rem; /* Aumentamos el tamaño de fuente */
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

/* Contenedor de datos */
.historial-pagos-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

/* Fila de selectores */
.select-row {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Estilos para los selectores */
.select-dropdown {
  max-width: 200px;
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

/* Contenedor de la gráfica */
.chart-container {
  width: 100%;
  max-width: 1000px;
  height: 500px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Mensajes de estado (cargando, error, etc.) */
.chart-container p {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-color);
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .chart-container {
    max-width: 100%;
    height: 400px;
  }
  .historial-servicios-title {
    font-size: 1.5rem;
  }
}
