/* Contenedor principal */
.lecturas-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f4f4f4;
    border-radius: 10px;
    
}

/* Sección de lecturas */
.lecturas-section {
    background-color: var(--background-color);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
}

/* Título de lecturas */
.lecturas-title {
    font-size: 1.5rem;
    color: var(--text-color);
    font-weight: bold;
    text-align: center;
    margin: 0;
}

/* Filtros de búsqueda */
.search-filters {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
}

.search-filters select,
.search-filters input {
    border: 1px solid var(--input-border);
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #fff;
}

.search-filters select {
    width: 180px;
}

.search-filters input {
    width: 150px;
}

/* Tabla de lecturas */
.lecturas-table {
    margin-top: 1rem;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.lecturas-table th,
.lecturas-table td {
    padding: 12px 15px;
    border: 1px solid var(--border-color);
    text-align: center;
    color: var(--text-color);
}

.lecturas-table th {
    background-color: var(--table-header);
    font-weight: bold;
    color: var(--table-header-text);
}

.lecturas-table tr:nth-child(odd) td {
    background-color: var(--table-cell-odd);
}

.lecturas-table tr:nth-child(even) td {
    background-color: var(--table-cell-pair);
}

.lecturas-table tr:hover td {
    background-color: var(--table-cell-hover);
    color: white;
    cursor: pointer;
}

/* Paginación */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.pagination button {
    background-color: var(--button-green);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: var(--button-hover-green);
}

.pagination button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.pagination select {
    border: 1px solid var(--input-border);
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: white;
    margin: 0 0.5rem;
}

/* Botón de ver foto */
.view-photo-button {
    background-color: #177de9;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.view-photo-button:hover {
    background-color: #0e5caf;
}

/*Ver Foto */

.view-photo-button:hover {
    background-color: #0e5caf;
}

.image-viewer-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-viewer-content {
    position: relative;
    background-color: transparent;
    border-radius: 10px;
    padding: 20px;
}

.image-viewer-image {
    max-width: 70vw; /* 70% del ancho de la pantalla */
    max-height: 70vh; /* 70% del alto de la pantalla */
    object-fit: contain;
    border: 5px solid white;
    border-radius: 10px;
}
