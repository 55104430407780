.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Fondo semitransparente */
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}

.modal-content {
    background: white;
    padding: 2rem;
    padding-top: 0.5rem;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    position: relative;
    animation: fadeIn 0.3s ease-out; /* Añadir una animación */
}

.recovery-boton-cerrar{
    display: flex;
    justify-content: right;
}

.recovery-boton-cerrar button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    margin: 0;
    font-size: 10px;
    font-weight: bold;
}

.modal-content h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content label {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
}

.modal-content input {
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.modal-content .btn-submit {
    padding: 0.8rem;
    background-color: var(--dark-green);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.modal-content .btn-submit:hover {
    background-color: var(--button-hover-green);
    transform: scale(1.05);
}

.modal-content .btn-submit:disabled {
    background-color: #ccc;
}

.modal-content .btn-close {
    padding: 0.5rem;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.modal-content .btn-close:hover {
    background-color: rgb(255, 149, 0);
    transform: scale(1.05);
}

/* Animación para el modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 600px) {
    .modal-overlay {
        position: fixed; /* Asegura que el overlay cubra toda la pantalla */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center; /* Centra horizontalmente */
        align-items: center; /* Centra verticalmente */
        background: rgba(0, 0, 0, 0.6); /* Fondo semitransparente */
        z-index: 1000;
    }

    .modal-content {
        max-width: 75%; /* Reducir el ancho máximo del modal para pantallas pequeñas */
        padding: 1.5rem;
        background: white;
        border-radius: 10px;
        margin: 0 auto; /* Asegura que esté centrado horizontalmente */
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        animation: fadeIn 0.3s ease-out; /* Añadir una animación */
    }

    .modal-content h2 {
        font-size: 1.5rem; /* Reducir el tamaño del título */
    }

    .modal-content label {
        font-size: 1rem; /* Reducir el tamaño de las etiquetas */
    }

    .modal-content input {
        padding: 0.7rem;
        font-size: 0.9rem; /* Reducir el tamaño de los campos de entrada */
    }

    .modal-content .btn-submit, 
    .modal-content .btn-close {
        padding: 0.7rem;
        font-size: 0.9rem; /* Ajustar los botones */
    }

    .recovery-boton-cerrar button {
        width: 1.2rem;
        height: 1.2rem;
        font-size: 8px; /* Ajustar el botón de cerrar en pantallas más pequeñas */
    }
}
